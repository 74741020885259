import axios from 'axios';
// import LocalStorageService from './LocalStoageService';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// LocalstorageService
// const localStorageService = LocalStorageService.getService();

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Token ' + token;
      localStorage.setItem('token', token);
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
      localStorage.removeItem('token');
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);
//Add a response interceptor

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    console.log('status ->>>', error.response);
    console.log('request ->>>', originalRequest.url);
    if (
      error.response.status === 400 &&
      originalRequest.url === `/api/accounts/user/login/`
    ) {
      //    router.push('/login');
      console.log('Si');
      localStorage.removeItem('token');
      // window.location.href = '/signin';
      return Promise.reject(error);
    }
    if (
      error.response.status === 400 &&
      originalRequest.url === `/api/accounts/user/refresh/`
    ) {
      //    router.push('/login');
      console.log('Si');
      localStorage.removeItem('token');
      window.location.href = '/signin';
      return Promise.reject(error);
    }
    if (error.response.status === 400 && !originalRequest._retry) {
      originalRequest._retry = true;
      const token = localStorage.getItem('token');
      return axiosInstance
        .post(
          '/api/accounts/user/refresh/',
          {},
          {
            headers: {
              Authorization: 'Token ' + token,
            },
          },
        )
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem('token', res.data.token);
            axiosInstance.defaults.headers.common['Authorization'] =
              'Token ' + localStorage.getItem('token');
            return axiosInstance(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
