import React from 'react';
import {Navigate} from 'react-router-dom';
import {RoutePermittedRole} from '@crema/constants/AppEnums';

const Contact = React.lazy(() => import('../../../modules/apps/Contact'));
const Orders = React.lazy(() => import('../../../modules/apps/Order'));
const Activities = React.lazy(() => import('../../../modules/apps/Activities'));
const Correctivo = React.lazy(() => import('../../../modules/apps/Correctivo'));
const Records = React.lazy(() =>
  import('../../../modules/apps/Assessment/Records'),
);

const Tasks = React.lazy(() => import('../../../modules/apps/Task'));

export const appsConfig = [
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: ['/apps/contact/folder/:name', '/apps/contact/label/:name'],
    element: <Contact />,
  },
  {
    permittedRole: [RoutePermittedRole.Admin],
    path: '/apps/contact',
    element: <Navigate to='/apps/contact/folder/all' />,
  },
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Scada,
      RoutePermittedRole.Security,
    ],
    path: ['/apps/orders/:name'],
    element: <Orders />,
  },
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Scada,
      RoutePermittedRole.Security,
    ],
    path: '/apps/orders',
    element: <Navigate to='/apps/orders/all' />,
  },
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Scada,
      RoutePermittedRole.Security,
    ],
    path: ['/apps/activities/:name'],
    element: <Activities />,
  },
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Scada,
      RoutePermittedRole.Security,
    ],
    path: '/apps/activities',
    element: <Navigate to='/apps/activities/all' />,
  },
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Scada,
      RoutePermittedRole.Security,
    ],
    path: ['/apps/correctivo/:name'],
    element: <Correctivo />,
  },
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Scada,
      RoutePermittedRole.Security,
    ],
    path: '/apps/correctivo',
    element: <Navigate to='/apps/correctivo/all' />,
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Security],
    path: ['/apps/records/:name'],
    element: <Records />,
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Security],
    path: '/apps/records',
    element: <Navigate to='/apps/records/all' />,
  },
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Scada,
      RoutePermittedRole.Security,
      RoutePermittedRole.Integrity,
    ],
    path: ['/apps/tasks/:name'],
    element: <Tasks />,
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Security],
    path: '/apps/tasks',
    element: <Navigate to='/apps/tasks/all' />,
  },
];
