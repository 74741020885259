import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axiosInstance from '../../helpers/axiosInstance';
import Swal from 'sweetalert2';
// import LocalStorageService from '../../helpers/LocalStoageService';
import axios from 'axios';

const axiosInstance2 = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// LocalstorageService
// const localStorageService = LocalStorageService.getService();

const OAuthContext = createContext();
const OAuthActionsContext = createContext();

export const useOAuth = () => useContext(OAuthContext);

export const useOAuthActions = () => useContext(OAuthActionsContext);

const OAuthProvider = ({children}) => {
  const [userData, setUserData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  // const dispatch = useDispatch();

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem('token');

      if (!token) {
        setUserData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        return;
      }
      // setAuthToken(token);
      axiosInstance
        .post(
          '/api/accounts/user/refresh/',
          {},
          {
            headers: {
              Authorization: 'Token ' + token,
            },
          },
        )
        .then(({data}) =>
          setUserData({
            user: data.user,
            isLoading: false,
            isAuthenticated: true,
          }),
        )
        .catch(() =>
          setUserData({
            user: undefined,
            isLoading: false,
            isAuthenticated: false,
          }),
        );
    };

    getAuthUser();
  }, []);

  const signInUser = async ({username, password}) => {
    try {
      const form = new FormData();
      form.append('username', username);
      form.append('password', password);
      // form.append('client_id', process.env.REACT_APP_CLIENT_ID);
      // form.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
      // form.append('grant_type', 'password');
      const {data} = await axiosInstance.post(
        '/api/accounts/user/login/',
        form,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      localStorage.setItem('token', data.token);
      // localStorageService.setToken(data);
      // const res = await axiosInstance.get('/api/accounts/user/profile/');
      setUserData({
        user: data.user,
        isAuthenticated: true,
        isLoading: false,
      });
    } catch (error) {
      Swal.fire({
        title: 'Usuario',
        text: `${error?.response?.data?.error}`,
        icon: 'error',
        confirmButtonText: 'Cerrar',
      });
      setUserData({
        ...userData,
        isAuthenticated: false,
        isLoading: false,
      });
    }
  };

  const signUpUser = async ({name, email, password}) => {
    try {
      const {data} = await axiosInstance.post('users', {
        name,
        email,
        password,
      });
      localStorage.setItem('token', data.token);
      const res = await axiosInstance.get('/auth');
      setUserData({
        user: res.data,
        isAuthenticated: true,
        isLoading: false,
      });
    } catch (error) {
      setUserData({
        ...userData,
        isAuthenticated: false,
        isLoading: false,
      });
      console.log('error:', error.response.data.error);
    }
  };

  const logout = async () => {
    let data = new FormData();
    data.append('token', localStorage.getItem('token'));
    await axiosInstance.post('/api/accounts/user/logout/', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    localStorage.removeItem('token');
    setUserData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  const forgetPasswordUser = async ({email}) => {
    try {
      let result = await axiosInstance2.post(
        '/api/accounts/request-reset-email/',
        {email},
      );
      return result.data;
    } catch (error) {
      return error?.response?.data;
    }
  };

  const resetPasswordUser = async ({password, token, uidb64, pin_code}) => {
    try {
      let result = await axiosInstance2.patch(
        '/api/accounts/password-reset-complete/',
        {password, token, uidb64, pin_code},
      );
      console.log('result ->', result);
      return result.data;
    } catch (error) {
      console.log('error pass ->', error?.response?.data);
      let data = {
        message: error?.response?.data?.detail,
        success: false,
      };
      return error?.response?.data?.detail ? data : error?.response?.data;
    }
  };

  return (
    <OAuthContext.Provider
      value={{
        ...userData,
      }}
    >
      <OAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout,
          setUserData,
          userData,
          forgetPasswordUser,
          resetPasswordUser,
        }}
      >
        {children}
      </OAuthActionsContext.Provider>
    </OAuthContext.Provider>
  );
};
export default OAuthProvider;

OAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
