import React from 'react';
import {
  MdOutlineDashboard,
  MdOutlineListAlt,
  MdOutlineDocumentScanner,
} from 'react-icons/md';
import {RoutePermittedRole} from '@crema/constants/AppEnums';

const routesConfig = [
  {
    id: 'app',
    title: 'Dashboard',
    messageId: 'sidebar.application',
    type: 'group',
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Scada,
      RoutePermittedRole.Security,
      RoutePermittedRole.Integrity,
    ],
    children: [
      {
        id: 'crypto',
        title: 'Panel',
        messageId: 'sidebar.app.dashboard.crypto',
        type: 'item',
        // permittedRole: [RoutePermittedRole.Admin],
        icon: <MdOutlineDashboard />,
        url: '/dashboard',
      },
    ],
  },
  {
    id: 'activity-management',
    title: 'Gestión de actividades',
    messageId: 'sidebar.apps',
    type: 'group',
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Scada,
      RoutePermittedRole.Security,
    ],
    children: [
      {
        id: 'activities',
        title: 'Activities',
        messageId: 'sidebar.apps.activities',
        type: 'item',
        icon: <MdOutlineListAlt />,
        url: '/apps/activities',
      },
      {
        id: 'orders',
        title: 'Orders',
        messageId: 'sidebar.apps.orders',
        type: 'item',
        // permittedRole: [RoutePermittedRole.Admin],
        icon: <MdOutlineDocumentScanner />,
        url: '/apps/orders',
      },
    ],
  },
  {
    id: 'integrity-assessment',
    title: 'Evaluación de integridad',
    messageId: 'sidebar.integrity.assessment',
    type: 'group',
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Security],
    children: [
      {
        id: 'records',
        title: 'Registros de evaluación',
        messageId: 'sidebar.list.assessment',
        type: 'item',
        // permittedRole: [RoutePermittedRole.Admin],
        icon: <MdOutlineListAlt />,
        url: '/apps/records',
      },
    ],
  },
];
export default routesConfig;
