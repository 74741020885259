import React from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';

const Dashboard = React.lazy(() => import('../../../modules/dashboards'));

export const dashBoardConfigs = [
  {
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.Integrity,
      RoutePermittedRole.Scada,
      RoutePermittedRole.Security,
    ],
    path: '/dashboard/',
    element: <Dashboard />,
  },
];
