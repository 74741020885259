import React, {useEffect, useState} from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Tooltip from '@mui/material/Tooltip';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import {ThemeMode} from '@crema/constants/AppEnums';
import {
  useThemeActionsContext,
  useThemeContext,
} from '@crema/context/AppContextProvider/ThemeContextProvider';
import {useSidebarActionsContext} from '@crema/context/AppContextProvider/SidebarContextProvider';
import {DarkSidebar, LightSidebar} from '@crema/constants/defaultConfig';

const ThemeModes = () => {
  const {updateThemeMode} = useThemeActionsContext();
  const {updateSidebarColorSet} = useSidebarActionsContext();
  const {themeMode} = useThemeContext();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const savedThemeMode = localStorage.getItem('themeMode');
    if (savedThemeMode) {
      updateThemeMode(savedThemeMode);
    } else {
      updateThemeMode(ThemeMode.LIGHT);
    }
    setMounted(true);
  }, [updateThemeMode]);

  useEffect(() => {
    if (mounted) {
      localStorage.setItem('themeMode', themeMode);
      applyTheme(themeMode);
    }
  }, [themeMode, mounted]);

  const applyTheme = (mode) => {
    if (mode === ThemeMode.LIGHT) {
      updateSidebarColorSet({
        sidebarBgColor: LightSidebar.sidebarBgColor,
        sidebarTextColor: LightSidebar.sidebarTextColor,
        sidebarMenuSelectedBgColor: LightSidebar.sidebarMenuSelectedBgColor,
        sidebarMenuSelectedTextColor: LightSidebar.sidebarMenuSelectedTextColor,
        sidebarHeaderColor: LightSidebar.sidebarHeaderColor,
      });
    } else {
      updateSidebarColorSet({
        sidebarBgColor: DarkSidebar.sidebarBgColor,
        sidebarTextColor: DarkSidebar.sidebarTextColor,
        sidebarMenuSelectedBgColor: DarkSidebar.sidebarMenuSelectedBgColor,
        sidebarMenuSelectedTextColor: DarkSidebar.sidebarMenuSelectedTextColor,
        sidebarHeaderColor: DarkSidebar.sidebarHeaderColor,
      });
    }
  };

  const onModeChange = (event, newThemeMode) => {
    if (newThemeMode) {
      updateThemeMode(newThemeMode);
      applyTheme(newThemeMode);
    }
  };

  return (
    <div>
      <ToggleButtonGroup
        value={themeMode}
        exclusive
        onChange={onModeChange}
        aria-label='theme mode'
      >
        <ToggleButton value={ThemeMode.LIGHT} aria-label='light mode'>
          <Tooltip title='Modo claro'>
            <WbSunnyIcon fontSize='small' />
          </Tooltip>
        </ToggleButton>

        <ToggleButton value={ThemeMode.DARK} aria-label='dark mode'>
          <Tooltip title='Modo oscuro'>
            <Brightness2Icon fontSize='small' />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default ThemeModes;
